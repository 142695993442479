import React from "react";

const MainTitle = () => {
  return (
    <h1 className="font-bold text-6xl text-center text-outline-primary ">
      YVY DRONE
    </h1>
  );
};

export default MainTitle;
